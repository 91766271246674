import React from 'react';
import { FcComments } from "react-icons/fc";

interface PlayerIconProps { }

export const CommentIcon: React.FC<PlayerIconProps> = () => {
  return (
    <FcComments className='size-6' />
  );
};
