import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

interface ItemListProps {
  items: { id: string; label: string; createdAt: string; createdBy?: string }[];
  onItemClick: (id: string) => void;
}

const ItemList: React.FC<ItemListProps> = ({ items, onItemClick }) => {
  return (
    <ul className="divide-y divide-gray-300">
      {items.map((item) => (
        <li
          key={item.id}
          className="py-4 px-2 hover:bg-gray-100 cursor-pointer transition"
          onClick={() => onItemClick(item.id)}
        >
          <div className="flex justify-between items-center">
            <span className="text-black font-bold">{item.label}</span>
            <div className="flex flex-col items-end">
              <span className="text-gray-500 text-sm">{dayjs(item.createdAt).format('YYYY/M/D H:mm')}</span>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ItemList;