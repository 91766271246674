import React from 'react';
import { AiOutlineFileDone } from "react-icons/ai";

interface CommandResultIconProps { }

export const CommandResultIcon: React.FC<CommandResultIconProps> = () => {
  return (
    <AiOutlineFileDone className='size-6' />
  );
};
