import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CommandDto, CommandResult } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import { CommandResultEditForm } from '../../organisms/CommandResultEditForm';
import { Breadcrumb, Title, Loading, useFetch } from '@viable-inc/fe-common';

export const CommandResultEdit: React.FC = () => {
  const { teamId, commandId, commandResultId } = useParams();

  const navigate = useNavigate();
  const { execFetch: fetchCommand, data: command } = useFetch<CommandDto>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });
  const { execFetch: fetchResult, data: result } = useFetch<CommandResult>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });
  useEffect(() => {
    fetchCommand(ENDPOINT.COMMAND(teamId as string, commandId as string));
    fetchResult(ENDPOINT.COMMAND_RESULT(teamId as string, commandResultId as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!command || !result) return <Loading />;
  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb
            items={[
            { label: 'エキスパート一覧', to: `/teams/${teamId}/players` },
            { label: '詳細', to: `/teams/${teamId}/players/${command.playerId}` },
            { label: 'リクエスト', to: `/teams/${teamId}/commands/${commandId}` },
            { label: '結果変更', to: `/teams/${teamId}/commands/${commandId}/results/${commandResultId}/edit` },
            ]}
            renderLink={(to, children) => <Link to={to} className="hover:underline transition">{children}</Link>}
          />
          <Title title="結果を変更する">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <CommandResultEditForm targetCommandResult={result} onComplete={(data: CommandResult) => {
        toast.success('結果を変更しました。');
        navigate(`/teams/${teamId}/commands/${data.commandId}/results/${data.id}`);
      }} />
    </div>
  );
};
