import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlayerDetailDto } from '../../dtos';
import { ENDPOINT } from '../../const';
import { PrimaryButton, TextInput, FormField, useUpdate } from '@viable-inc/fe-common';
import { toast } from 'react-toastify';

interface Props {
  onComplete: (player: PlayerDetailDto) => void;
}

export const PlayerCreateForm: React.FC<Props> = ({ onComplete }) => {
  const { teamId } = useParams();
  const [name, setName] = useState('');
  // const [detail, setDetail] = useState('');
  const { execUpdate: createPlayer, submitting } = useUpdate<{ name: string, content: string, parentId?: string }, PlayerDetailDto>({
    path: ENDPOINT.PLAYERS(teamId as string),
    method: 'POST',
    onComplete: async (data: PlayerDetailDto) => {
      onComplete(data);
    },
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  return (
    <form className="space-y-4">
      <p className="mb-8 text-base text-gray-500">あなた色の専門家「エキスパート」を、AIに依頼して作成しましょう。役割を与え、チューニングをすることで、永遠に進化を続けます。</p>
      <FormField id="name" label="あなたが今必要としている専門家は？" required>
        <TextInput placeholder="●から■を考えて▲してくれる専門家" value={name} onChange={setName} />
      </FormField>
      <div className='space-y-2'>
        <p className='text-sm text-gray-500'>例）課題から課題解決の手順を高いレベルで提案してくれる凄腕ビジネスマン</p>
        <p className='text-sm text-gray-500'>例）タスクを分解して最適な実行順を提案してくれるプロジェクトマネージャー</p>
        <p className='text-sm text-gray-500'>例）お客様への適切な返事を提案できる敏腕秘書</p>
        <p className='text-sm text-gray-500'>例）新規のビジネス立ち上げや既存ビジネスの方針変更から法律の問題が発生しないかをしっかり考える弁護士</p>
        <p className='text-sm text-gray-500'>例）アイディアをカタチにする説明上手なアイディアマン</p>
        <p className='text-sm text-gray-500'>例）異なる部署や取引先とのコミュニケーションを円滑に整理提案してくれるマネージャー</p>
        <p className='text-sm text-gray-500'>etc...</p>
      </div>
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton loading={submitting} disabled={submitting || name.length === 0} fullWidth text={'AIに内容を最適化させて作成する'} loadingText='作成中...しばらくお待ちください' onClick={async () => {
          await createPlayer({ name, content: `あなたは${name}の専門家です。その道のプロとして高い視点で、専門性を持ってます。また、人が理解しやすい構成で内容を考え、丁寧に理解できるように説明する達人です。` });
      }} />
      </div>
    </form>
  );
};
