import React, { useCallback, useEffect, useRef } from 'react';
import { CommentIcon } from '../atoms/CommentIcon';
import { useLocation, useNavigate } from 'react-router-dom';

// SubTitle のプロパティ型定義
interface Props { }

export const CommentSectionTitle: React.FC<Props> = () => {
  const commentRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const scrollToComment = useCallback(() => {
    if (commentRef.current) {
      commentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      navigate(`${location.pathname}`);
    }
  }, [commentRef, location, navigate]);

  useEffect(() => {
    if (location.hash === '#comment' && commentRef.current) {
      scrollToComment();
    }
  }, [location, scrollToComment]);

  return (
    <div ref={commentRef} id="comment" className="flex items-center space-x-1">
      <CommentIcon />
      <h2 className="text-lg md:text-xl font-semibold text-gray-700">
        コメント
      </h2>
    </div>
  );
};