import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading, getCookie, deleteCookie, handleApiResponse } from '@viable-inc/fe-common';
import { ENDPOINT } from '../../const';
import { toast } from 'react-toastify';
import { COOKIE } from '../../const';

export const LineCallback: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isCalledRef = useRef(false);

  useEffect(() => {
    const cookieState = getCookie(COOKIE.LINE_STATE);
    const paramState = searchParams.get('state');
    if (paramState && (!cookieState || cookieState !== paramState)) {
      toast.error('LINEログインは異なるブラウザ間の遷移では行えません。同じブラウザで遷移されるように操作をやり直してください。');
      navigate('/login');
      deleteCookie(COOKIE.LINE_STATE);
    }
  }, [navigate, searchParams]);

  const lineLogin = useCallback(async (code: string) => {

    if (isCalledRef.current) return;
    isCalledRef.current = true;

    const controller = new AbortController();
    const { signal } = controller;

    try {
      const response = await fetch(ENDPOINT.LOGIN_LINE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, redirectUri: `${process.env.REACT_APP_BASE_URL}/line/callback` }),
        credentials: 'include',
        signal,
      });
      const isResponseOk = await handleApiResponse(response, (errorMsg) => {
        toast.error(errorMsg);
      });
      if (isResponseOk) {
        navigate(getCookie(COOKIE.LOGIN_REDIRECT) || '/teams');
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error('Fetch error:', error.message);
        }
      } else {
        console.error('An unknown error occurred');
      }
    }

    return () => {
      controller.abort();
    };
  }, [navigate]);

  useLayoutEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      const timer = setTimeout(() => {
        lineLogin(code);
      }, 0);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [searchParams, lineLogin]);

  return <Loading />;
};
