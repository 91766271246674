import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { CommandDto, CommandResult, CommandStep, CommandStepBase, PlayerDetailDto } from '../../dtos';
import { CommandIcon } from '../atoms/CommandIcon';
import dayjs from 'dayjs';
import { ENDPOINT } from '../../const';
import { useNavigate } from 'react-router-dom';
import { CommandResultIcon } from '../atoms/CommandResultIcon';
import { LinkButton, useFetch } from '@viable-inc/fe-common';
import { toast } from 'react-toastify';

interface CommandFlowProps {
  parentCommand?: CommandDto;
  player: PlayerDetailDto;
  steps: (CommandStep | CommandStepBase)[];
}

export const CommandFlow: React.FC<CommandFlowProps> = ({ parentCommand, player, steps }) => {
  return (
    <VerticalTimeline>
      {
        steps.map((step, idx) => (
          <CommandFlowItem key={idx} player={player} step={step} />
        ))
      }
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName='text-gray-500'
        date={parentCommand ? dayjs(parentCommand.createdAt).format('YYYY/M/D H:mm') : undefined}
        iconStyle={{ background: '#fff', color: '#000' }}
        icon={<CommandIcon />}
      >
        <div>
          {
            parentCommand && (
              <span className='text-gray-500 text-xs font-semibold'>今回のリクエスト</span>
            )
          }
          <h2 className='font-semibold'>{parentCommand ? parentCommand.summary : '今回のリクエスト'}</h2>
        </div>
      </VerticalTimelineElement>
    </VerticalTimeline>
  );
};

interface CommandFlowItemProps {
  player: PlayerDetailDto;
  step: CommandStep | CommandStepBase;
}

const CommandFlowItem: React.FC<CommandFlowItemProps> = ({ player, step }) => {
  const navigate = useNavigate();

  const createdAt = 'createdAt' in step ? dayjs(step.createdAt).format('YYYY/M/D H:mm') : undefined;
  const [showDetail, setShowDetail] = useState(false);
  const { execFetch: fetchCommand, data: command } = useFetch<CommandDto>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });
  const { execFetch: fetchParentCommand, data: parentCommand } = useFetch<CommandDto>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });
  const { execFetch: fetchCommandResult, data: commandResult } = useFetch<CommandResult>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  useEffect(() => {
    if (step.tableName === 'commands') {
      fetchCommand(ENDPOINT.COMMAND(player.teamId, step.primaryId));
    }
    if (step.tableName === 'command_results') {
      fetchCommandResult(ENDPOINT.COMMAND_RESULT(player.teamId, step.primaryId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (command) {
      fetchParentCommand(ENDPOINT.COMMAND(player.teamId, command.parentId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command]);

  if (command && parentCommand) {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName='text-gray-500'
        date={createdAt}
        iconStyle={{ background: '#fff', color: '#000' }}
        icon={<CommandIcon />}
      >
        <p onClick={() => {
          navigate(`/teams/${player.teamId}/players/${player.id}/commands/${command.id}`);
        }} className='cursor-pointer absolute -top-6 -left-2 px-1 bg-white text-blue-500 rounded-full transition-transform transform-gpu border-2 border-blue-500'>v{command.versionNo}</p>
        <div onClick={() => setShowDetail(v => !v)} className='flex justify-between items-center space-x-2 cursor-pointer'>
          <h2 className='font-semibold'>{parentCommand.summary}</h2>
        </div>
        <div className='text-sm flex justify-end'>
          <LinkButton onClick={() => setShowDetail(v => !v)} text={showDetail ? '閉じる' : '開く'} />
        </div>
        {
          showDetail && (
            <p onClick={() => setShowDetail(v => !v)} className='whitespace-pre-line text-gray-500'>
              {command.content}
            </p>
          )
        }
      </VerticalTimelineElement>
    );
  }

  if (commandResult) {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        dateClassName='text-gray-500'
        iconStyle={{ background: '#fff', color: '#000' }}
        date={createdAt}
        icon={<CommandResultIcon />}
      >
        <p onClick={() => {
          navigate(`/teams/${player.teamId}/players/${player.id}/commands/${commandResult.commandId}/results/${commandResult.id}`);
        }} className='cursor-pointer absolute -top-6 -left-2 px-1 bg-white text-blue-500 rounded-full transition-transform transform-gpu border-2 border-blue-500'>v{commandResult.versionNo}</p>
        <p onClick={() => setShowDetail(v => !v)} className='whitespace-pre-line text-gray-500 cursor-pointer'>
          {showDetail ? commandResult.content : `${commandResult.content.slice(0, 100)}${commandResult.content.length > 100 ? '...' : ''}`}
        </p>
        <div className='text-sm flex justify-end'>
          <LinkButton onClick={() => setShowDetail(v => !v)} text={showDetail ? '閉じる' : '開く'} />
        </div>
      </VerticalTimelineElement>
    );
  }

  return null;
};