import React from 'react';
import { FcConferenceCall } from "react-icons/fc";

interface TeamIconProps { }

export const TeamIcon: React.FC<TeamIconProps> = () => {
  return (
    <FcConferenceCall className='size-6' />
  );
};
