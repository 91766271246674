import React, { useCallback } from 'react';
import { BiComment } from "react-icons/bi";
import { useNavigate, useLocation } from 'react-router-dom';

interface CommentCountProps {
  count: number;
  clickable?: boolean;
}

export const CommentCount: React.FC<CommentCountProps> = ({ count, clickable }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // クリック時のハンドラ関数
  const handleClick = useCallback(() => {
    if (clickable) {
      const currentPathname = location.pathname;
      const currentSearch = location.search;
      navigate(`${currentPathname}${currentSearch}#comment`);
    }
  }, [clickable, location, navigate]);

  return (
    <div onClick={handleClick} className={`flex items-center space-x-1 ${clickable ? 'border px-4 py-1 rounded-full bg-white cursor-pointer text-base' : 'text-sm'}`}>
      <BiComment size={clickable ? 20 : 16} />
      <span>{count}件</span>
    </div>
  );
};
