import React from 'react';
import lineIcon from '../../assets/line.png';
import { COOKIE } from '../../const';
import { setCookie } from '@viable-inc/fe-common';

interface LineBtnProps {
  redirectUri: string;
}

export const handleLineLogin = (state: string, redirectUri: string) => {
  window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_LINE_CHANNEL_ID}&redirect_uri=${redirectUri}&state=${state}&scope=profile%20openid`;
};

const LineLoginButton: React.FC<LineBtnProps> = ({ redirectUri }) => {

  return (
    <button
      onClick={() => {
        const state = Math.random().toString(36).substring(2, 15);
        setCookie(COOKIE.LINE_STATE, state);
        handleLineLogin(state, redirectUri);
      }}
      className={`
        flex items-center justify-center
        w-64 h-12 rounded-md
        transition-colors duration-200
        bg-[#06C755] hover:bg-[#06C755]/90 active:bg-[#06C755]/70
      `}
    >
      <div className="flex items-center space-x-2">
        <LineIcon />
        <span
          className={`
            font-bold text-xl text-white
          `}
        >
          LINEでログイン
        </span>
      </div>
    </button>
  );
};

const LineIcon: React.FC = () => (
  <img width={40} height={40} src={lineIcon} alt="Line" />
);

export default LineLoginButton;