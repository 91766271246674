import React, { useEffect, useState, ReactNode, createContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COOKIE, ENDPOINT } from '../../const';
import { AuthDto } from '../../dtos';
import { Loading, setCookie } from '@viable-inc/fe-common';

interface AuthProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthDto | null>(null);

export const AuthLayout: React.FC<AuthProps> = ({ children }) => {
  const location = useLocation();
  const [auth, setAuth] = useState<AuthDto | null>(null);
  const navigate = useNavigate();

  const fetchCheckAuth = useCallback(async () => {
    const response = await fetch(ENDPOINT.CHECK_AUTH, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // エラーレスポンス
    if (!response.ok) {
      setCookie(COOKIE.LOGIN_REDIRECT, location.pathname);
      navigate('/login');
      return;
    }
    const authData = await response.json();
    if (!authData) {
      setCookie(COOKIE.LOGIN_REDIRECT, location.pathname);
      navigate('/login');
      return;
    }
    setAuth(authData);
  }, [navigate, setAuth, location.pathname]);

  useEffect(() => {
    fetchCheckAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!auth) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <Loading />
      </div>
    );
  }

  return (
    <AuthContext.Provider value={auth}>
      <main className="container mx-auto">
        {children}
      </main>
    </AuthContext.Provider>
  );
};
