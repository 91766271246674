import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PlayerDetailDto } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import { PlayerEditForm } from '../../organisms/PlayerEditForm';
import { Breadcrumb, Title, Loading, useFetch } from '@viable-inc/fe-common';
export const PlayerEdit: React.FC = () => {
  const { teamId, playerId } = useParams();

  const breadcrumbItems = [
    { label: 'エキスパート一覧', to: `/teams/${teamId}/players` },
    { label: '詳細', to: `/teams/${teamId}/players/${playerId}` },
    { label: '変更', to: `/teams/${teamId}/players/${playerId}/edit` },
  ];
  const navigate = useNavigate();
  const { execFetch: fetchData, data, loading } = useFetch<PlayerDetailDto>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });
  useEffect(() => {
    fetchData(ENDPOINT.PLAYER(teamId as string, playerId as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !data) return <Loading />;
  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb
            items={breadcrumbItems}
            renderLink={(to, children) => <Link to={to} className="hover:underline transition">{children}</Link>}
          />
          <Title title="エキスパートを変更する">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <PlayerEditForm targetPlayer={data} onComplete={(data: PlayerDetailDto) => {
        toast.success('エキスパートを変更しました。');
        navigate(`/teams/${teamId}/players/${data.parentId}#latest`);
      }} />
    </div>
  );
};
