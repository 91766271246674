import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PrimaryButton } from '@viable-inc/fe-common';
import { TeamDto, UserDto } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import ItemList from '../../molecules/ItemList';
import { toast } from 'react-toastify';
import { Breadcrumb, Title, Loading, Modal, TextInput, SelectInput, EditButton, useUpdate, useFetch } from '@viable-inc/fe-common';
import { TeamIcon } from '../../atoms/TeamIcon';

export const TeamDetail: React.FC = () => {
  const { teamId } = useParams();
  const breadcrumbItems = [
    { label: 'チーム', to: `/admin/teams` },
    { label: '詳細', to: `/admin/teams/${teamId}` },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [anotherName, setAnotherName] = useState('');
  const [deleteTargetUserId, setDeleteTargetUserId] = useState<string | null>(null);
  const [addTargetUserId, setAddTargetUserId] = useState('');

  const { execFetch: fetchTeamDetail, data: teamDetail, loading: teamDetailLoading } = useFetch<{ team: TeamDto, users: UserDto[] }>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    }
  });
  const { execFetch: fetchUsers, data: users, loading: usersLoading } = useFetch<UserDto[]>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  const { execUpdate: updateTeam, submitting: updating } = useUpdate<{ name: string, anotherName: string | null }, null>({
    method: 'PUT',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: async () => {
      toast.success('チーム名を変更しました。');
      await fetchTeamDetail(`${ENDPOINT.TEAMS}/${teamId}`);
    },
  });

  const { execUpdate: createBelonging, submitting: creating } = useUpdate<null, null>({
    method: 'POST',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: async () => {
      toast.success('ユーザーを追加しました。');
      await fetchTeamDetail(`${ENDPOINT.TEAMS}/${teamId}`);
    },
  });

  const { execUpdate: deleteBelonging, submitting: deleting } = useUpdate<null, null>({
    method: 'DELETE',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: async () => {
      toast.success('ユーザーを除外しました。');
      await fetchTeamDetail(`${ENDPOINT.TEAMS}/${teamId}`);
    },
    onFailure: (e) => {
      toast.error('ユーザーを除外できませんでした。');
    },
  });

  useEffect(() => {
    fetchTeamDetail(`${ENDPOINT.TEAMS}/${teamId}`);
    fetchUsers(`${ENDPOINT.USERS}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (teamDetail) {
      setName(teamDetail.team.name);
      setAnotherName(teamDetail.team.anotherName ?? '');
    }
  }, [teamDetail]);

  if (teamDetailLoading) return <Loading />;

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb
            items={breadcrumbItems}
            renderLink={(to, children) => <Link to={to} className="hover:underline transition">{children}</Link>}
          />
          <div className='flex items-center space-x-2'>
            <Title title={`${teamDetail?.team.name ?? ""}${teamDetail?.team.anotherName ? `(${teamDetail?.team.anotherName})` : ""}`}>
            <TeamIcon />
            </Title>
            <EditButton onClick={() => setIsEditModalOpen(true)} />
          </div>
        </div>
      </div>
      <div className="mt-6">
        <PrimaryButton fullWidth text="ユーザーを追加する" onClick={() => setIsModalOpen(true)} />
      </div>
      <div className='mt-4'>
        {
          !teamDetail ? (
            <Loading />
          ) : teamDetail.users.length > 0 ? (
            <ItemList
                items={teamDetail.users.map((user) => ({ id: user.id, label: `${user.displayName} - ${user.email ?? 'メールアドレスなし'}`, createdAt: user.createdAt }))}
              onItemClick={(id) => {
                setDeleteTargetUserId(id);
              }}
            />
          ) : (null)
        }
      </div>
      <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)} title="チーム名を変更する">
        <form className="space-y-4">
          <TextInput
            placeholder="チーム名"
            value={name}
            onChange={(value) => setName(value)}
          />
          <TextInput
            placeholder="隠しチーム名"
            value={anotherName}
            onChange={(value) => setAnotherName(value)}
          />
          <PrimaryButton disabled={name.length === 0 || updating} loading={updating} fullWidth text="チーム名を変更する" onClick={async () => {
            await updateTeam({ name, anotherName: anotherName.length > 0 ? anotherName : null }, `${ENDPOINT.TEAM(teamId as string)}`);
            setIsEditModalOpen(false);
          }} />
        </form>
      </Modal>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title="ユーザーを追加する">
        <form className="space-y-4">
          {
            usersLoading || !users ? (
              <Loading />
            ) : (
              <SelectInput
                value={addTargetUserId}
                  options={[{ label: 'ユーザーを選択してください', value: '' }, ...users.filter((user) => !teamDetail?.users.some((teamUser) => teamUser.id === user.id)).map((user) => ({ label: `${user.displayName} - ${user.email ?? 'メールアドレスなし'}`, value: user.id }))]}
                onChange={(value) => setAddTargetUserId(value)}
              />
            )
          }
          <PrimaryButton disabled={addTargetUserId.length === 0 || creating} loading={creating} fullWidth text="ユーザーを追加する" onClick={async () => {
            await createBelonging(null, ENDPOINT.TEAM_BELONGING(teamId as string, addTargetUserId));
            setIsModalOpen(false);
          }} />
        </form>
      </Modal>
      <Modal isOpen={!!deleteTargetUserId} onClose={() => setDeleteTargetUserId(null)} title="ユーザーを除外する">
        <form className="space-y-4">
          <p>「{users?.find((user) => user.id === deleteTargetUserId)?.displayName}」を除外します。よろしいですか。</p>
          <PrimaryButton disabled={!deleteTargetUserId || deleting} loading={deleting} fullWidth text="ユーザーを除外する" onClick={async () => {
            await deleteBelonging(null, ENDPOINT.TEAM_BELONGING(teamId as string, deleteTargetUserId as string));
            setDeleteTargetUserId(null);
          }} />
        </form>
      </Modal>
    </div>
  );
};
