// command-status-tag.tsx
import React from 'react';
import { CommandStatus, nameByCommandStatus } from '../../enums/command-status';

type CommandStatusTagProps = {
    status: CommandStatus;
};

const statusColors = {
    executing: 'bg-gray-300 text-gray-800',
    completed: 'bg-green-600 text-white',
    failed: 'bg-red-600 text-white',
};

const CommandStatusTag: React.FC<CommandStatusTagProps> = ({ status }) => {
    const classNames = `inline-block px-3 py-1 rounded-full font-semibold ${statusColors[status]}`;

    return (
        <span className={classNames}>
            {nameByCommandStatus(status)}
        </span>
    );
};

export default CommandStatusTag;