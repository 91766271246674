// CommandStatus の型定義
export type CommandStatus = 'executing' | 'completed' | 'failed';

// CommandStatus の定数定義
export const COMMAND_STATUS = {
    EXECUTING: 'executing' as CommandStatus,
    COMPLETED: 'completed' as CommandStatus,
    FAILED: 'failed' as CommandStatus,
} as const;

export const nameByCommandStatus = (status: CommandStatus): string => {
    switch (status) {
        case COMMAND_STATUS.EXECUTING:
            return '実行中';
        case COMMAND_STATUS.COMPLETED:
            return '完了';
        case COMMAND_STATUS.FAILED:
            return '失敗';
        default:
            throw new Error(`Invalid command status: ${status}`);
    }
}