import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT } from '../../../const';
import { Loading, useFetch } from '@viable-inc/fe-common';
import { toast } from 'react-toastify';

export const SystemPlayerSelect: React.FC = () => {
  const navigate = useNavigate();

  const { execFetch: fetchData, data: systemPlayerCodes, loading } = useFetch<string[]>({
    path: ENDPOINT.SYSTEM_PLAYER_CODES,
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !systemPlayerCodes) {
    return (
      <Loading />
    )
  }
  return (
    <div className='space-y-2'>
      {
        systemPlayerCodes.map(code => {
          return (
            <div className='cursor-pointer font-semibold px-4 py-2 rounded-full bg-gray-100 hover:bg-gray-200' key={code} onClick={() => navigate(`/admin/system-players/${code}`)}>
              {code}
            </div>
          );
        })
      }
    </div>
  )
};
