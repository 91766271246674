import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../templates/AuthLayout';
import { PrimaryButton, setCookie } from '@viable-inc/fe-common';
import { Loading } from '@viable-inc/fe-common';
import { logout } from '../../firebase';
import { COOKIE, STORAGE } from '../../const';

export const TeamSelect: React.FC = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const [viewportHeight, setViewportHeight] = useState('100vh');

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(`${window.innerHeight}px`);
    };

    // 初期設定
    updateViewportHeight();

    // リサイズイベントリスナーを追加
    window.addEventListener('resize', updateViewportHeight);

    // クリーンアップ関数
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);

  // ログアウト処理
  const handleLogout = useCallback(async () => {
    await logout(() => {
      setCookie(COOKIE.LOGIN_REDIRECT, location.pathname);
      navigate('/login');
    });
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (auth?.teams?.length === 1) {
      navigate(`/teams/${auth?.teams[0].id}/players`, { replace: true });
      return;
    }
    const currentTeamId = localStorage.getItem(STORAGE.CURRENT_TEAM_ID);
    if (currentTeamId) {
      navigate(`/teams/${currentTeamId}/players`, { replace: true });
    }
  }, [auth, navigate]);

  if (!auth || auth?.teams?.length === 1) {
    return (
      <Loading />
    )
  }
  return (
    <div
      className="flex justify-center items-center"
      style={{ height: viewportHeight, overflow: 'hidden' }}
    >
      {
        auth.teams.length === 0 ? (
          <div className='space-y-4'>
            <p className='font-semibold text-lg text-center'>所属しているチームがありません。</p>
            <PrimaryButton fullWidth onClick={handleLogout} text='ログアウトする' />
          </div>
        ) : (
          <div className='space-y-4'>
            <h1 className='font-bold text-xl text-center'>チーム選択</h1>
            <div className='space-y-2'>
              {
                auth.teams.map(team => {
                  return (
                    <div
                      className={`${id === team.id ? 'border-blue-900 bg-blue-50 hover:bg-blue-200' : 'border-gray-900 bg-white hover:bg-gray-200'} border-2 font-bold rounded-full text-gray-900 px-12 py-2 transition`}
                      key={team.id}
                      onClick={() => navigate(`/teams/${team.id}/players`)}>
                      {team.name}
                    </div>
                  );
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
};
