import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthLayout';
import { PrimaryButton, MenuButton, Modal, SecondaryButton, setCookie } from '@viable-inc/fe-common';
import { Outlet } from 'react-router-dom';
import { logout } from '../../firebase';
import { COOKIE } from '../../const';

export const AuthAdminLayout: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  if (!["hatakeyama@viable.jp", "ohya@viable.jp"].includes(auth?.user?.email || '')) {
    return (
      <div>
        <p className='pt-8 pb-2 text-xl text-bold text-center'>アクセスする権限がありません。</p>
        <PrimaryButton fullWidth onClick={() => navigate('/teams')} text='所属チームを確認する' />
      </div>
    );
  }
  return (
    <div className='px-2 py-8'>
      <div className="z-10 fixed bottom-4 right-2 sm:right-4">
        <div className='flex flex-col space-y-2'>
          <MenuButton onClick={() => setIsMenuOpen(true)} />
        </div>
      </div>
      <Modal isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} title={'管理メニュー'}>
        <div className='flex flex-col space-y-2'>
          <SecondaryButton text='チーム選択' onClick={() => {
            navigate(`/teams`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='チーム一覧' onClick={() => {
            navigate(`/admin/teams`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='システムエキスパート一覧' onClick={() => {
            navigate(`/admin/system-players`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='ログアウト' onClick={async () => {
            await logout(() => {
              setCookie(COOKIE.LOGIN_REDIRECT, location.pathname);
              navigate('/login');
            });
            setIsMenuOpen(false);
          }} />
        </div>
      </Modal>
      <div className='mb-20'>
        <Outlet />
      </div>
    </div>
  );
};
