import React from 'react';
import { toast } from 'react-toastify';
import { FaRegCopy } from "react-icons/fa6";

interface CopyButtonProps { }

const CopyButton: React.FC<CopyButtonProps> = () => {
  const copyCurrentUrl = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success('このページのURLをコピーしました。');
    } catch (err) {
      console.error('Failed to copy URL: ', err);
    }
  };
  return (
    <button
      onClick={copyCurrentUrl}
      className={`border-2 border-blue-500 p-1 text-blue-500 bg-white font-bold rounded-full`}
    >
      <FaRegCopy size={20} />
    </button>
  );
};

export default CopyButton;