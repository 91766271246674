import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useParams, useLocation, BrowserRouter } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from './components/pages/Login';
import { AuthLayout } from './components/templates/AuthLayout';
import { AuthTeamLayout } from './components/templates/AuthTeamLayout';
import { PlayerNew } from './components/pages/team/PlayerNew';
import { PlayerList } from './components/pages/team/PlayerList';
import { PlayerDetail } from './components/pages/team/PlayerDetail';
import { TeamSelect } from './components/pages/TeamSelect';
import { AuthAdminLayout } from './components/templates/AuthAdminLayout';
import { TeamList } from './components/pages/admin/TeamList';
import { TeamDetail } from './components/pages/admin/TeamDetail';
import { SystemPlayerSelect } from './components/pages/admin/SystemPlayerSelect';
import { SystemPlayerDetail } from './components/pages/admin/SystemPlayerDetail';
import { PlayerEdit } from './components/pages/team/PlayerEdit';
import { CommandDetail } from './components/pages/team/CommandDetail';
import { CommandResultEdit } from './components/pages/team/CommandResultEdit';
import { CommandList } from './components/pages/team/CommandList';
import { LineCallback } from './components/pages/LineCallback';
import { MyPage } from './components/pages/team/MyPage';
import { LineCollaborateCallback } from './components/pages/LineCollaborateCallback';
import { LineAmazonCallback } from './components/pages/LineAmazonCallback';
import { LoginAmazon } from './components/pages/LoginAmazon';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Slide}
        toastClassName={() =>
          "text-black font-medium bg-white p-2"
        }
        closeButton={false}
      />
      <Routes>
        <Route path="/" element={<Navigate to="/teams" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/amazon/login" element={<LoginAmazon />} />
        <Route path="/line/callback" element={<LineCallback />} />
        <Route path="/line/amazon/callback" element={<LineAmazonCallback />} />
        <Route path="/line/collaborate/callback" element={<LineCollaborateCallback />} />
        <Route
          path="/teams"
          element={
            <AuthLayout>
              <TeamSelect />
            </AuthLayout>
          }
        />
        <Route
          path="/admin"
          element={
            <AuthLayout>
              <AuthAdminLayout />
            </AuthLayout>
          }
        >
          <Route path="" element={<Navigate to="/admin/teams" />} />
          <Route path="teams" element={<TeamList />} />
          <Route path="teams/:teamId" element={<TeamDetail />} />
          <Route path="system-players" element={<SystemPlayerSelect />} />
          <Route path="system-players/:code" element={<SystemPlayerDetail />} />
        </Route>

        <Route
          path="/teams/:teamId"
          element={
            <AuthLayout>
              <AuthTeamLayout />
            </AuthLayout>
          }
        >
          <Route path="mypage" element={<MyPage />} />
          <Route path="players" element={<PlayerList />} />
          <Route path="players/new" element={<PlayerNew />} />
          <Route path="players/:playerId" element={<PlayerDetail />} />
          <Route path="players/:playerId/edit" element={<PlayerEdit />} />
          <Route path="commands" element={<CommandList />} />
          <Route path="commands/:commandId" element={<CommandDetail />} />
          <Route path="commands/:commandId/results/:commandResultId" element={<CommandDetail />} />
          <Route path="commands/:commandId/results/:commandResultId/edit" element={<CommandResultEdit />} />

          {/* 古いパスへのアクセスを新しいパスにリダイレクト */}
          <Route
            path="players/:playerId/commands/:commandId"
            element={<RedirectToNewPath to="/teams/:teamId/commands/:commandId" />}
          />
          <Route
            path="players/:playerId/commands/:commandId/results/:commandResultId"
            element={<RedirectToNewPath to="/teams/:teamId/commands/:commandId/results/:commandResultId" />}
          />
          <Route
            path="players/:playerId/commands/:commandId/results/:commandResultId/edit"
            element={<RedirectToNewPath to="/teams/:teamId/commands/:commandId/results/:commandResultId/edit" />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/teams" />} />
      </Routes>
    </BrowserRouter>
  );
};

const RedirectToNewPath: React.FC<{ to: string }> = ({ to }) => {
  const params = useParams();
  const newPath = Object.entries(params).reduce(
    (path, [key, value]) => path.replace(`:${key}`, value || ''),
    to
  );
  return <Navigate to={newPath} replace />;
};

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;