import React from 'react';
import { FcSportsMode } from "react-icons/fc";

interface CommentCountProps {
  count: number;
}

export const CommandCount: React.FC<CommentCountProps> = ({ count }) => {
  return (
    <div className={`flex items-center space-x-1 text-sm`}>
      <FcSportsMode size={16} />
      <span>{count}件</span>
    </div>
  );
};
