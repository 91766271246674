import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from './AuthLayout';
import { PrimaryButton, MenuButton, Modal, SecondaryButton, setCookie, useFetch } from '@viable-inc/fe-common';
import { Outlet } from 'react-router-dom';
import { UserDto } from '../../dtos';
import { COOKIE, ENDPOINT, STORAGE } from '../../const';
import CopyButton from '../atoms/CopyButton';
import { logout } from '../../firebase';
import { toast } from 'react-toastify';

export const TeamMembersContext = createContext<UserDto[] | null>(null);

export const AuthTeamLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const [teamMembers, setTeamMembers] = useState<UserDto[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { execFetch: fetchData, data } = useFetch<UserDto[]>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  const auth = useContext(AuthContext);

  useEffect(() => {
    if (teamId) {
      fetchData(ENDPOINT.TEAM_USERS(teamId as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    if (auth && teamId) {
      if (auth?.teams?.some(team => team.id === teamId)) {
        localStorage.setItem(STORAGE.CURRENT_TEAM_ID, teamId);
      } else {
        localStorage.removeItem(STORAGE.CURRENT_TEAM_ID);
      }
    }
  }, [auth, teamId]);

  useEffect(() => {
    if (data) {
      setTeamMembers(data);
    }
  }, [data]);

  if (!auth?.teams?.some(team => team.id === teamId)) {
    return (
      <div>
        <p className='pt-8 pb-2 text-xl text-bold text-center'>アクセスする権限がありません。</p>
        <PrimaryButton fullWidth onClick={() => navigate('/teams')} text='所属チームを確認する' />
      </div>
    );
  }
  return (
    <div className='px-2 py-8'>
      <div className="z-10 fixed bottom-20 right-2 sm:right-4">
        <div className='flex flex-col space-y-2'>
          <MenuButton onClick={() => setIsMenuOpen(true)} />
          <CopyButton />
        </div>
      </div>
      <Modal isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} title={auth.teams.find(team => team.id === teamId)?.name}>
        <div className='flex flex-col space-y-2'>
          <SecondaryButton text='エキスパート一覧' onClick={() => {
            navigate(`/teams/${teamId}/players`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='リクエスト履歴' onClick={() => {
            navigate(`/teams/${teamId}/commands`);
            setIsMenuOpen(false);
          }} />
          <SecondaryButton text='マイページ' onClick={() => {
            navigate(`/teams/${teamId}/mypage`);
            setIsMenuOpen(false);
          }} />
          {
            auth.user.email === 'hatakeyama@viable.jp' && (
              <SecondaryButton text='管理画面' onClick={() => {
                navigate(`/admin`);
                setIsMenuOpen(false);
              }} />
            )
          }
          <SecondaryButton text='ログアウト' onClick={async () => {
            await logout(() => {
              setCookie(COOKIE.LOGIN_REDIRECT, location.pathname);
              navigate('/login');
            });
            setIsMenuOpen(false);
          }} />
        </div>
      </Modal>
      <div className='mb-20'>
        <TeamMembersContext.Provider value={teamMembers}>
          <Outlet />
        </TeamMembersContext.Provider>
      </div>
    </div>
  );
};
