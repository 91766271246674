import React, { useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PrimaryButton, Breadcrumb, Title, Loading, Paging, useFetch } from '@viable-inc/fe-common';
import { CommandListItemDto, PagingDto } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import CommandItemList from '../../molecules/CommandItemList';
import { toast } from 'react-toastify';

const defaultPage = 1;
const defaultPageSize = 50;

export const CommandList: React.FC = () => {
  const { teamId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const breadcrumbItems = [
    { label: 'リクエスト履歴', to: `/teams/${teamId}/commands` },
  ];
  const navigate = useNavigate();
  const { execFetch: fetchParentCommands, data: pagingData } = useFetch<PagingDto<CommandListItemDto>>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  useEffect(() => {
    let page = parseInt(searchParams.get('page') || defaultPage.toString(), 10);
    page = isNaN(page) ? defaultPage : page;
    let pageSize = parseInt(searchParams.get('pageSize') || defaultPageSize.toString(), 10);
    pageSize = isNaN(pageSize) ? defaultPageSize : pageSize;
    fetchParentCommands(`${ENDPOINT.COMMANDS(teamId as string)}?page=${page}&pageSize=${pageSize}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (!pagingData) return <Loading />;

  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb
            items={breadcrumbItems}
            renderLink={(to, children) => <Link to={to} className="hover:underline transition">{children}</Link>}
          />
          <Title title="リクエスト履歴">
            <PlayerIcon />
          </Title>
        </div>
        <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
          <PrimaryButton fullWidth text="エキスパート一覧へ" onClick={() => navigate(`/teams/${teamId}/players`)} />
        </div>
      </div>
      <div>
        {
          !pagingData ? (
            <Loading />
          ) : pagingData.items.length > 0 ? (
              <div className='space-y-4'>
                <Paging
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  data={pagingData}
                  onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                  defaultPage={defaultPage}
                  defaultPageSize={defaultPageSize}
                />
                <CommandItemList
                  data={pagingData}
                  onItemClick={(item) => {
                    navigate(`/teams/${teamId}/players/${item.playerId}/commands/${item.id}`);
                  }}
                />
                <Paging
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  data={pagingData}
                  onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                  defaultPage={defaultPage}
                  defaultPageSize={defaultPageSize}
                />
              </div>
          ) : (null)
        }
      </div>
    </div>
  );
};
