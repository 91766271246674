import React, { useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PrimaryButton, Breadcrumb, Title, Loading, Paging, useFetch } from '@viable-inc/fe-common';
import { PagingDto, PlayerListItemDto } from '../../../dtos';
import { ENDPOINT } from '../../../const';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import PlayerItemList from '../../molecules/PlayerItemList';
import { toast } from 'react-toastify';

const defaultPage = 1;
const defaultPageSize = 50;

export const PlayerList: React.FC = () => {
  const { teamId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const breadcrumbItems = [
    { label: 'エキスパート一覧', to: `/teams/${teamId}/players` },
  ];
  const navigate = useNavigate();
  const { execFetch: fetchData, data, loading } = useFetch<PagingDto<PlayerListItemDto>>({
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
  });

  useEffect(() => {
    let page = parseInt(searchParams.get('page') || defaultPage.toString(), 10);
    page = isNaN(page) ? defaultPage : page;
    let pageSize = parseInt(searchParams.get('pageSize') || defaultPageSize.toString(), 10);
    pageSize = isNaN(pageSize) ? defaultPageSize : pageSize;
    fetchData(`${ENDPOINT.PARENT_PLAYERS(teamId as string)}?page=${page}&pageSize=${pageSize}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (loading) return <Loading />;

  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb
            items={breadcrumbItems}
            renderLink={(to, children) => <Link to={to} className="hover:underline transition">{children}</Link>}
          />
          <Title title="エキスパート一覧">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text="エキスパートを作る" onClick={() => navigate(`/teams/${teamId}/players/new`)} />
      </div>
      <div>
        {
          !data ? (
            <Loading />
          ) : data.items.length > 0 ? (
              <div className='space-y-4'>
              <Paging
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                data={data}
                onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                defaultPage={defaultPage}
                defaultPageSize={defaultPageSize}
              />

              <PlayerItemList
                  data={data}
                onItemClick={(item) => {
                  navigate(`/teams/${teamId}/players/${item.id}#latest`);
              }}
            />
                <Paging
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  data={data}
                  onPageChange={(page, pageSize) => setSearchParams({ page: page.toString(), pageSize: pageSize.toString() })}
                  defaultPage={defaultPage}
                  defaultPageSize={defaultPageSize}
                />
              </div>
          ) : (null)
        }
      </div>
    </div>
  );
};
