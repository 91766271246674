import React from 'react';
import { FcSportsMode } from "react-icons/fc";

interface CommandIconProps { }

export const CommandIcon: React.FC<CommandIconProps> = () => {
  return (
    <FcSportsMode className='size-6' />
  );
};
