import React from 'react';
import { loginWithGoogle } from '../../firebase';
import { FcGoogle } from 'react-icons/fc';
import { handleApiResponse } from '@viable-inc/fe-common';
import { toast } from 'react-toastify';
interface GoogleBtnProps {
  onLoading: (loading: boolean) => void;
  onComplete: () => void;
}

const GoogleLoginButton: React.FC<GoogleBtnProps> = ({
  onLoading,
  onComplete,
}) => {
  const handleLogin = async () => {
    onLoading(true);
    const response = await loginWithGoogle();
    const isResponseOk = await handleApiResponse(response, (errorMsg) => {
      toast.error(errorMsg);
    });
    if (!isResponseOk) {
      onLoading(false);
      return;
    };
    onComplete();
  };

  return (
    <button
      onClick={handleLogin}
      className={`
        text-xl w-64 h-12 border border-gray-300 rounded-md flex items-center justify-center space-x-2 bg-white text-gray-700 font-semibold py-2 px-4 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50
      `}
    >
      <div className="flex items-center space-x-2">
        <FcGoogle className="size-7" />
        <span>Googleでログイン</span>
      </div>
    </button>
  );
};

export default GoogleLoginButton;