import React from 'react';
import { FcPortraitMode } from "react-icons/fc";

interface PlayerIconProps { }

export const PlayerIcon: React.FC<PlayerIconProps> = () => {
  return (
    <FcPortraitMode className='size-6' />
  );
};
