import React, { useEffect, useState } from 'react';
import logo from '../../assets/amazon-logo.svg';
import LineLoginButton from '../atoms/LineLoginButton';
import { Loading } from '@viable-inc/fe-common';
import GoogleLoginButton from '../atoms/GoogleLoginButton';

export const LoginAmazon: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const [viewportHeight, setViewportHeight] = useState('100vh');

  useEffect(() => {
    const updateViewportHeight = () => {
      setViewportHeight(`${window.innerHeight}px`);
    };

    // 初期設定
    updateViewportHeight();

    // リサイズイベントリスナーを追加
    window.addEventListener('resize', updateViewportHeight);

    // クリーンアップ関数
    return () => window.removeEventListener('resize', updateViewportHeight);
  }, []);

  if (loading) {
    return <div className='flex justify-center items-center'><Loading /></div>;
  };

  return (
    <div
      className="flex justify-center items-center"
      style={{ height: viewportHeight, overflow: 'hidden' }}
    >
      <div className="space-y-2 text-center">
        <div className='flex flex-col items-center'>
          <img width={150} src={logo} alt="amazon logo" />
        </div>
        <div className="space-y-2 flex flex-col items-center">
          <LineLoginButton redirectUri={`${process.env.REACT_APP_BASE_URL}/line/amazon/callback`} />
          {
            isLineInAppBrowser() ? (
              <div className='pt-2 px-2 text-xs text-gray-500'>
                <p className='text-left'>
                  GoogleログインはLINEのメッセージ上からはご利用いただけません。
                </p>
                <p className='text-left'>
                  Googleでログインされたい方は、safariやchromeなどのブラウザからアクセスしてください。
                </p>
              </div>
            ) : (
              <GoogleLoginButton
                onLoading={setLoading}
                onComplete={
                  () => {
                    window.location.href = `${process.env.REACT_APP_AMAZON_BASE_URL}`;
                  }
                }
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

const isLineInAppBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('line');
};

