import React, { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../templates/AuthLayout';
import { FaUser } from 'react-icons/fa';
import lineIcon from '../../../assets/line.png';
import { FcGoogle } from 'react-icons/fc';
import { PrimaryButton, Breadcrumb, Title, Loading, SubTitle, setCookie } from '@viable-inc/fe-common';
import { COOKIE } from '../../../const';
import { handleLineLogin } from '../../atoms/LineLoginButton';

const LineIcon: React.FC = () => (
  <img width={40} height={40} src={lineIcon} alt="Line" />
);

export const MyPage: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  // const [displayName, setDisplayName] = useState('');
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   if (auth) {
  //     setDisplayName(auth.user.displayName);
  //   }
  // }, [auth]);

  const breadcrumbItems = [
    { label: 'マイページ', to: `/mypage` },
  ];

  if (!auth) {
    return (
      <Loading />
    )
  }

  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb
            items={breadcrumbItems}
            renderLink={(to, children) => <Link to={to} className="hover:underline transition">{children}</Link>}
          />
          <Title title="マイページ">
            <FaUser />
          </Title>
        </div>
      </div>
      <div className='space-y-4'>
        <SubTitle title="基本情報" />
        <div className='space-y-2'>
          <div>
            <p className='font-bold text-xs text-gray-500'>表示名</p>
            <div className='flex items-center space-x-2'>
              <p>{auth.user.displayName}</p>
              {/* <EditButton onClick={() => setIsModalOpen(true)} /> */}
            </div>
          </div>
        </div>
        <div className='space-y-2'>
          <div>
            <p className='font-bold text-xs text-gray-500'>メールアドレス</p>
            <p>{auth.user.email ?? 'なし'}</p>
          </div>
        </div>
      </div>
      <div className='space-y-4'>
        <SubTitle title="ログイン方法" />
        <div>
          <div className='flex items-center space-x-2'>
            <div className='font-bold text-gray-500'>
              {
                auth.user.googleId && !auth.user.lineId ? (<FcGoogle className="size-7" />) : !auth.user.googleId && auth.user.lineId && !auth.user.googleId ? (<LineIcon />) : auth.user.googleId && auth.user.lineId ? (
                  <div className='flex items-center space-x-2'>
                    <FcGoogle className="size-7" />
                    <LineIcon />
                  </div>
                ) : 'なし'
              }
            </div>
            {
              auth.user.googleId && !auth.user.lineId && (
                <button
                  onClick={() => {
                    const state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                    setCookie(COOKIE.LINE_STATE, state);
                    setCookie(COOKIE.LINE_DESTINATION, window.location.pathname);
                    handleLineLogin(state, `${process.env.REACT_APP_BASE_URL}/line/collaborate/callback`);
                  }}
                  className={`
  flex items-center justify-center
  h-12 rounded-md
  px-2
  transition-colors duration-200
  bg-[#06C755] hover:bg-[#06C755]/90 active:bg-[#06C755]/70
`}
                >
                  <div className="flex items-center space-x-2">
                    <LineIcon />
                    <span
                      className={`
      font-bold text-lg text-white
    `}
                    >
                      LINEログイン連携
                    </span>
                  </div>
                </button>
              )
            }
          </div>
          {
            auth.user.googleId && !auth.user.lineId && (
              <div>
                <p className='pt-2 text-xs text-gray-500'>このアカウントでLINEログイン連携すると、過去のLINEログインで作成したデータは閲覧できなくなります。ご注意ください。</p>
              </div>
            )
          }
        </div>
      </div>
      <div className='space-y-4'>
        <SubTitle title="チーム" />
        <div className='space-y-2'>
          {
            auth.teams.map(team => {
              return (
                <div
                  className={`relative ${teamId === team.id ? 'border-gray-800 bg-white hover:border-gray-900' : 'border-gray-100 bg-white hover:border-gray-200'} border-2 font-bold text-gray-900 full-width rounded px-4 py-2 mb-2 transition`}
                  key={team.id}
                  onClick={() => navigate(`/teams/${team.id}/mypage`)}>
                  {
                    teamId === team.id && (
                      <div className='absolute -top-2 -right-2 bg-gray-900 text-white text-xs rounded-full px-2 py-1'>
                        選択中
                      </div>
                    )
                  }
                  <span>
                    {team.name}
                  </span>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className='z-10 fixed bottom-4 right-0 px-2 sm:px-0 sm:w-auto w-full sm:mr-4'>
        <PrimaryButton fullWidth text={'エキスパート一覧に戻る'} onClick={() => {
          navigate(`/teams/${teamId}/players`);
        }} />
      </div>
      {/* <Modal title='表示名を変更する' isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className='space-y-4'>
          <TextInput value={displayName} onChange={(v) => setDisplayName(v)} errorMessages={displayName.length === 0 ? ['表示名を入力してください'] : []} />
          <PrimaryButton text='表示名を変更する' fullWidth onClick={() => {
            console.log('変更');
          }} />
        </div>
      </Modal> */}
    </div>
  );
};
