import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { ENDPOINT } from './const';
import { handleApiResponse } from '@viable-inc/fe-common';
import { toast } from 'react-toastify';
const firebaseConfig = {
  apiKey: "AIzaSyC3pNFXIphrw5pbvWegBJ4aMO1FyTe-w94",
  authDomain: "unitiq-39746.firebaseapp.com",
  projectId: "unitiq-39746",
  storageBucket: "unitiq-39746.appspot.com",
  messagingSenderId: "85813924816",
  appId: "1:85813924816:web:8e29d504378fa0a9d79fd5",
  measurementId: "G-060GEHJRE7"
};

// Firebaseの初期化
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

export const loginWithGoogle = async () => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  const idToken = await result.user.getIdToken();
  return await fetch(ENDPOINT.LOGIN_GOOGLE, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ idToken, authProvider: 'google' }),
    credentials: 'include',
  });
};

// ログアウト処理
export const logout = async (onComplete: () => void) => {
  const auth = getAuth();
  await signOut(auth);
  const response = await fetch(ENDPOINT.LOGOUT, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const isResponseOk = await handleApiResponse(response, (errorMsg) => {
    toast.error(errorMsg);
  });
  if (!isResponseOk) return;
  onComplete();
};


export { auth, provider };