export const ENDPOINT = {
  USERS: process.env.REACT_APP_API_URL + '/api/v1/users',
  USERS_DISPLAY_NAME: process.env.REACT_APP_API_URL + '/api/v1/users/display-name',
  LOGIN_GOOGLE: process.env.REACT_APP_API_URL + '/api/v1/google/login',
  LOGIN_LINE: process.env.REACT_APP_API_URL + '/api/v1/line/login',
  COLLABORATE_LINE: process.env.REACT_APP_API_URL + '/api/v1/line/collaborate',
  LOGOUT: process.env.REACT_APP_API_URL + '/api/v1/logout',
  CHECK_AUTH: process.env.REACT_APP_API_URL + '/api/v1/check-auth',
  TEAMS: process.env.REACT_APP_API_URL + '/api/v1/teams',
  TEAM: (teamId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId,
  TEAM_USERS: (teamId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/users',
  TEAM_COMMENTS: (teamId: string, tableName: string, primaryId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/comments?tableName=' + tableName + '&primaryId=' + primaryId,
  TEAM_BELONGING: (teamId: string, userId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/belongings/' + userId,
  SYSTEM_PLAYER: (code: string) => process.env.REACT_APP_API_URL + '/api/v1/system-players/' + code,
  SYSTEM_PLAYER_CODES: process.env.REACT_APP_API_URL + '/api/v1/system-player-codes',
  PARENT_PLAYERS: (teamId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/parent-players',
  PARENT_PLAYER: (teamId: string, parentPlayerId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/parent-players/' + parentPlayerId,
  PLAYERS: (teamId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/players',
  PLAYER: (teamId: string, playerId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/players/' + playerId,
  PLAYER_ASSIGN: (teamId: string, playerId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/players/' + playerId + '/assign',
  COMMANDS: (teamId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/commands',
  PLAYER_COMMANDS: (teamId: string, playerId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/players/' + playerId + '/commands',
  COMMAND_DETAIL: (teamId: string, commandId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/commands/' + commandId + '/detail',
  PARENT_COMMAND: (teamId: string, parentCommandId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/parent-commands/' + parentCommandId,
  COMMAND: (teamId: string, commandId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/commands/' + commandId,
  COMMAND_RESULT: (teamId: string, commandResultId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/command-results/' + commandResultId,
  COMMANDS_COMMAND_RESULT: (teamId: string, commandId: string) => process.env.REACT_APP_API_URL + '/api/v1/teams/' + teamId + '/commands/' + commandId + '/command-results',
}

export const STORAGE = {
  CURRENT_TEAM_ID: 'current-team-id',
  COMMAND_NEW_DATA: 'command-new-data',
}

export const COOKIE = {
  LOGIN_REDIRECT: 'login-redirect',
  LINE_STATE: 'line-state',
  LINE_DESTINATION: 'line-destination',
}
