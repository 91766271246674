import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommandDto, CommandStepBase, PlayerDetailDto } from '../../dtos';
import { ENDPOINT } from '../../const';
import { PrimaryButton, Modal, FullScreenDialog, FormField, Textarea, SelectInput, useUpdate } from '@viable-inc/fe-common';
import { toast } from 'react-toastify';
import { CommandFlow } from '../organisms/CommandFlow';

export type CommandModalData = {
  initialPlayerId?: string;
  players: PlayerDetailDto[];
  baseSteps: CommandStepBase[] | null;
  editCommand: CommandDto | null;
}

interface Props {
  data: CommandModalData | null,
  onClose: () => void;
  onComplete: (created: CommandDto) => void;
}

export const CommandModal: React.FC<Props> = ({ data, onClose, onComplete }) => {
  const { teamId } = useParams();
  const [content, setContent] = useState('');
  const [showDetail, setShowDetail] = useState(false);

  const [selectedPlayer, setSelectedPlayer] = useState<PlayerDetailDto | null>(null);

  const { initialPlayerId, players, baseSteps, editCommand } = data ?? { players: [], baseSteps: null, editCommand: null };

  const { execUpdate: createCommand, submitting } = useUpdate<{ content: string, baseSteps: CommandStepBase[], parentId?: string }, CommandDto>({
    method: 'POST',
    onError: (errorMsg) => {
      toast.error(errorMsg);
    },
    onComplete: async (data: CommandDto) => {
      toast.success(editCommand ? '内容を変更してリクエストしました。' : !!baseSteps && baseSteps.length > 0 ? '続けてリクエストしました。' : '新しくリクエストしました。');
      onComplete(data);
    },
  });

  useEffect(() => {
    setContent(editCommand?.content ?? '');
  }, [editCommand]);

  useEffect(() => {
    if (players.length > 0) {
      if (initialPlayerId) {
        setSelectedPlayer(players.find(d => d.id === initialPlayerId) ?? players[0]);
      } else {
        setSelectedPlayer(players[0]);
      }
    }
  }, [players, initialPlayerId]);

  return (
    <div>
      <Modal isOpen={!!baseSteps} onClose={onClose} title={editCommand ? '内容を変更してリクエストする' : !!baseSteps && baseSteps.length > 0 ? '続けてリクエストする' : '新しくリクエストする'}>
        <form className="space-y-4">
          {
            selectedPlayer && (
              <FormField label='対応するエキスパート' id='player'>
                <div className='flex items-center space-x-2'>
                  <span>{players.find(d => d.id === d.parentId)?.summary}</span>
                  <div style={{ minWidth: '60px' }}>
                    <SelectInput
                      options={players.map(d => ({ label: `v${d.versionNo}`, value: d.id }))}
                      value={selectedPlayer.id}
                      onChange={(value) => setSelectedPlayer(players.find(d => d.id === value) ?? players[0])}
                    />
                  </div>
                </div>
              </FormField>
            )
          }
          <p onClick={() => setShowDetail(true)} className='text-right text-blue-500 cursor-pointer hover:underline'>リクエストの詳細な流れを確認する</p>
          <Textarea placeholder={"何をリクエストする？\n"} rows={10} errorMessages={content.length > 0 ? [] : ['入力してください']} value={content} onChange={setContent} />
          <PrimaryButton loading={submitting} disabled={submitting || content.length === 0} fullWidth text={editCommand ? '内容を変更してリクエストする' : !!baseSteps && baseSteps.length > 0 ? '続けてリクエストする' : '新しくリクエストする'} onClick={async () => {
            await createCommand({ content, baseSteps: baseSteps ?? [], parentId: editCommand?.parentId ?? undefined }, ENDPOINT.PLAYER_COMMANDS(teamId as string, selectedPlayer?.id ?? ''));
          }} />
        </form>
      </Modal>
      <FullScreenDialog
        isOpen={showDetail}
        onClose={() => setShowDetail(false)}
        title='詳細な流れの確認'
      >
        {
          selectedPlayer && baseSteps && (
            <div className='space-y-4'>
              <div className='space-y-2'>
                <div className='flex items-center space-x-1'>
                  <p className="font-semibold">{players.find(d => d.id === selectedPlayer.parentId)?.summary}</p>
                  <span className="text-sm px-1 font-bold border border-gray-500 rounded-full">v{selectedPlayer.versionNo}</span>
                </div>
                <div className={`whitespace-pre-line text-sm`}>
                  {selectedPlayer.content}
                </div>
              </div>
              <CommandFlow player={selectedPlayer} steps={baseSteps} />
            </div>
          )
        }
      </FullScreenDialog>
    </div>
  );
};
