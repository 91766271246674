import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PlayerDetailDto } from '../../../dtos';
import { PlayerCreateForm } from '../../organisms/PlayerCreateForm';
import { PlayerIcon } from '../../atoms/PlayerIcon';
import { Breadcrumb, Title } from '@viable-inc/fe-common';

export const PlayerNew: React.FC = () => {
  const { teamId } = useParams();
  const breadcrumbItems = [
    { label: 'エキスパート一覧', to: `/teams/${teamId}/players` },
    { label: 'エキスパートを作る', to: `/teams/${teamId}/players/new` },
  ];
  const navigate = useNavigate();

  return (
    <div className='space-y-8'>
      <div className="flex justify-between items-center">
        <div className='space-y-2'>
          <Breadcrumb
            items={breadcrumbItems}
            renderLink={(to, children) => <Link to={to} className="hover:underline transition">{children}</Link>}
          />
          <Title title="エキスパートを作る">
            <PlayerIcon />
          </Title>
        </div>
      </div>
      <PlayerCreateForm onComplete={(data: PlayerDetailDto) => {
        toast.success('エキスパートを作成しました。');
        navigate(`/teams/${teamId}/players/${data.id}`);
      }} />
    </div>
  );
};
